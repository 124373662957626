import { COLORS, THEME } from "./constants";
import { theme } from "./theme";

export const styles = {
    title: (fontSize?: number, color?: string) => ({
        color: color || THEME.DOMINANT, 
        fontFamily: theme.typography.fontFamily,
        fontSize: fontSize || THEME.FONT.HEADING(),
    }),
    
    button: (width?: number, disabled?: boolean) => ({
        height: 40,
        borderRadius: 0,
        boxShadow: "none",
        width: width || 275,
        color: COLORS.WHITE,
        margin: `7.5px 0 0 0`,
        textTransform: "none",
        fontSize: THEME.FONT.SUB_HEADING(),
        backgroundColor: disabled? "#0000008a" : THEME.BUTTON_ACCENT,
        '&:hover': {
            backgroundColor: disabled? "#0000008a" : THEME.ACTIVE_ACCENT,
        },
        '&:active': {
            backgroundColor: disabled? "#0000008a" : THEME.ACTIVE_ACCENT,
        },
    }),

    formField: (disable?: boolean) => ({
        width: 275,
        margin: 7.5,
        borderRadius: 0,
        fontSize: THEME.FONT.PARAGRAPH(),
        '&:active': {
            border: disable? "" : `1px solid ${COLORS.WHITE}`
        }
    }),

    formFieldHelper: () => ({
        margin: "0 7.5px",
        fontSize: THEME.FONT.PARAGRAPH(), 
        color: COLORS.LIGHT_GRAY,
        '&:hover': {
            color: THEME.DOMINANT
        }
    }),

    /*** Navigation ***/
    navRail: () => ({
        margin: 20,
        alignSelf: "flex-start"
    }),

    navRailLink: () => ({
        padding: "5px 5px 5px 0",
        '&:hover': {
            color: THEME.BACKGROUND_ACCENT,
        },
    }),

    navRailLinkText: (active?: boolean, fontSize?: string | number, hoverColor?: string) => ({
        color: active? THEME.DOMINANT : THEME.TEXT,
        fontSize: fontSize || THEME.FONT.PARAGRAPH(),
        '&:hover': {
            color: hoverColor? hoverColor : active? THEME.DOMINANT : THEME.ACTIVE_ACCENT,
        },
    }),

    navArrayLink: (active?: boolean, backgroundColor?: string) => ({
        width: "130px", 
        height: "130px", 
        margin: "10px",
        borderRadius: 5,
        textDecoration: "none", 
        backgroundColor: `${backgroundColor || THEME.BUTTON_ACCENT}`,
    })
}