import * as React from 'react';

import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { ThemeProvider, createTheme } from '@mui/material';

import './index.css';
import { THEME } from './constants';
import { AuthProvider } from './context/auth';
import { Loading, Notification, useViewport } from './support';
import { getDevOrDepUrl, useCustomState } from './utils';
import { loadingContextType, notificationContextType, notificationType, loadingType } from './types';

import Sign from './pages/sign';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

export const LoadingContext = React.createContext<loadingContextType | null>(null);
export const NotificationContext = React.createContext<notificationContextType | null>(null);

function App({...props}) {
  const [loading, setLoading] = useCustomState<loadingType>({});
  const [notification, setNotification] = useCustomState<notificationType>({});

  const theme = createTheme({
    typography: {
      fontFamily: 'Helios Extended',
      body1: {
        color: THEME.TEXT
      }
    }
  })

  const {width} = useViewport();

  React.useEffect(() => {
  }, [width]);

  return (
    <LoadingContext.Provider value={{loading: loading, setLoading: setLoading}}>
      <NotificationContext.Provider value={{notification: notification, setNotification: setNotification}}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <Routes>
              {/*** Add defualt url handle ***/}
              <Route path={getDevOrDepUrl("fab") || "/"} element={<Sign />} />
            </Routes>
          </BrowserRouter>
        </ThemeProvider>
        <Loading loading={loading} />
        <Notification notification={notification} setNotification={setNotification} duration={6000}/>
      </NotificationContext.Provider>
    </LoadingContext.Provider>
  )
}

root.render(
  // <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
