import * as React from "react";

import { Button } from "@mui/material";

import "../assets/utils.css";

import { styles } from "../styles";
import { NotificationContext } from "..";
import { useCustomState} from "../utils";
import { useAuth } from "../context/auth";
import { signupFormType } from "../types/forms";
import { FormField, PassFormField } from "../forms/fields";
import { validateEmail, validateField, validatePassword } from "../utils/forms";

interface SignupProps extends React.HTMLAttributes<HTMLDivElement> {
}

function SignupForm(props: SignupProps) {
    const {...domProps} = props;
    const {style, className, ...rest} = domProps; 

    const auth = useAuth();
    const notification = React.useContext(NotificationContext);

    const [form, setForm] = useCustomState<signupFormType>({});

    const validate = (): boolean => {
        setForm({
            email: validateEmail(form.email.value),
            lastName: validateField(form.lastName.value),
            firstName: validateField(form.firstName.value),

            password: validatePassword(form.password.value),
            confirmPassword: validatePassword(form.password.value, form.confirmPassword.value),
        })
        return true;
    }

    const signup = async () => {
        if (validate() && form.email && form.password) {
            const uid = await auth?.signup(form.email.value, form.password.value);

            if (uid?.message === "Firebase: Error (auth/email-already-in-use).") {
                notification?.setNotification({message: "Email already in use.", notify: true});
                return;
            } else {
                // signup user and redirect
            }
        }
    }

    return (
        <>
            <FormField 
                variant="outlined" 
                placeholder="First name"
                value={form.firstName?.value || ""}
                style={{...styles.formField()}} 
                helperText={form.firstName?.message || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setForm({firstName: validateField(event.target.value)})}
            />

            <FormField 
                variant="outlined" 
                placeholder="Last name"
                value={form.lastName?.value || ""}
                style={{...styles.formField()}} 
                helperText={form.lastName?.message || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setForm({lastName: validateField(event.target.value)})}
            />

            <FormField 
                variant="outlined" 
                placeholder="Email"
                value={form.email?.value || ""}
                style={{...styles.formField()}} 
                helperText={form.email?.message || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>   setForm({email: validateEmail(event.target.value)})}
            />

            <PassFormField 
                variant="outlined" 
                placeholder="Password"
                value={form.password?.value || ""}
                style={{...styles.formField()}} 
                helperText={form.password?.message || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setForm({password: validatePassword(event.target.value)})}
            />
            
            <PassFormField 
                variant="outlined" 
                placeholder="Confirm password"
                style={{...styles.formField()}} 
                value={form.confirmPassword?.value || ""}
                helperText={form.confirmPassword?.message || ""}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setForm({confirmPassword: validatePassword(form.password.value, event.target.value)})}
            />
    
            <Button sx={{...styles.button()}} variant="contained" onClick={signup}>{"Signup"}</Button>
        </>
    )
}

export default SignupForm;
