import * as React from "react";

import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";

import "../assets/utils.css";

import { styles } from "../styles";
import { THEME } from "../constants";
import { NotificationContext } from "..";
import { useAuth } from "../context/auth";
import { signinFormType } from "../types/forms";
import { addToLocalStorage, getDevOrDepUrl, useCustomState} from "../utils";
import { FormField, PassFormField } from "../forms/fields";
import { validateEmail, validatePassword } from "../utils/forms";

interface SigninProps extends React.HTMLAttributes<HTMLDivElement> {
}

function SigninForm(props: SigninProps) {
    const {...domProps} = props;
    const {style, className, ...rest} = domProps; 

    const auth = useAuth();
    const navigate = useNavigate();
    const notification = React.useContext(NotificationContext);

    const [form, setForm] = useCustomState<signinFormType>({});
    const [resetPass, setResetPass] = React.useState<boolean>(false);
    const [forgotPass, setForgotPass] = React.useState<boolean>(false);

    const validate = (): boolean => {
        setForm({
            email: validateEmail(form.email.value),
            password: validatePassword(form.password.value),
        })
        return true;
    }

    const signin = async () => {
        if (forgotPass) {
            auth?.sendResetPasswordEmail(form.email.value);
        } else if (resetPass) {
            await auth?.updatePassword(form.password.value);
        } else if (validate()) {
            const uid = await auth?.login(form.email.value, form.password.value);

            if (uid?.message === "Firebase: Error (auth/email-already-in-use).") {
                notification?.setNotification({message: "Email already in use.", notify: true});
                return;
            } else {
                await auth?.login(form.email.value, form.password.value)
            }
        }
    }

    React.useEffect(() => {
        const checkResetPassWrapper = async () => {
            const needsPassChange = await auth?.needsPasswordChange()
            if (needsPassChange) { 
                setResetPass(needsPassChange)
            } else if (auth?.id) {
                switch (auth.user?.role) {
                    case "tester":
                        addToLocalStorage("fabAuthenticatedFor", auth.user.projects[0])
                        window.location.href = `https://fabhous.com/${auth.user.projects[0]}`;
                        return;
                    case "admin":
                    default: return;
                }
            }
        }
        checkResetPassWrapper()
    }, [auth?.isAuthenticated])

    return (
        <>
        {!resetPass?
            <FormField 
                variant="outlined" 
                placeholder="Email"
                value={form.email?.value || ""}
                helperText={form.email?.message || ""}
                style={{...styles.formField()}} 
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setForm({email: validateEmail(event.target.value)})}
            />
            : <Typography fontSize={16} color={THEME.TEXT} style={{marginBottom: 20}}>Make a new password</Typography>
        }
        {!forgotPass?
            <div className="flex column">
                <PassFormField 
                    variant="outlined" 
                    value={form.password?.value || ""}
                    helperText={form.password?.message || ""}
                    style={{...styles.formField()}} 
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setForm({password: validatePassword(event.target.value)})}
                /> 
                <Typography className="pointer" sx={{...styles.formFieldHelper()}} onClick={() => setForgotPass(true)}>Forgot password</Typography>
            </div>
            : <></>
        }

        <Button sx={{...styles.button()}} variant="contained" onClick={signin}>{
            !forgotPass? !resetPass? "Signin" : "Update password" : "Send reset email"
        }</Button>
        </>
    )
}

export default SigninForm;
