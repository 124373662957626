import * as React from "react";

import { Button, Typography } from "@mui/material";

import "../assets/utils.css";
import logo from "../assets/media/logo.png";
import name from "../assets/media/name.png";


import { styles } from "../styles";
import { COLORS } from "../constants";
import SigninForm from "../forms/signin";
import SignupForm from "../forms/signup";

interface SignProps extends React.PropsWithChildren<any> {
}

function Sign(props: SignProps) {
    const [signup, setSignup] = React.useState<boolean>(false);

    return (
        <div className="width-100 column flex height-100 align-center justify-center">
            <div className="flex align-center" style={{marginBottom: 20}} onClick={() => {}}>
                <img src={logo} height="50px" className="margin-top-20px pointer"></img>
                <img src={name} height="85px" className="margin-top-20px pointer" style={{margin: "0 0 0 15px"}}></img>
            </div>
            {!signup? <SigninForm /> : <SignupForm />}
            <Button 
                sx={{...styles.button(), color: COLORS.LIGHT_GRAY, backgroundColor: COLORS.TRANSPARENT}} 
                onClick={() => setSignup(!signup)}
            >
                {signup? "Signin" : "Signup"}
            </Button> 
        </div>
    )
}

export default Sign;
